import { Button } from '@mui/material'
import { IconX } from '@tabler/icons'
import { enqueueMessage, closeMessage } from 'store/actions'

export const messageResolve = (store, response) => {
    const { dispatch } = store || {}
    const { type, msg } = response || {}
    const { status, collection_id, collection_name, rst, err_msg } = msg || {}

    const enqueueBar = (...args) => dispatch(enqueueMessage(...args))
    const closeBar = (...args) => dispatch(closeMessage(...args))

    const key = new Date().getTime() + Math.random()
    const options = {
        key,
        variant: 'info',
        persist: false,
        autoHideDuration: 4000,
        action: (key) => (
            <Button style={{ color: 'white' }} onClick={() => closeBar(key)}>
                <IconX />
            </Button>
        )
    }
    let message
    let variant = 'info'
    switch (type) {
        case 'COLLECTION_IMPORT':
        case 'COLLECTION_ADD_LIST':
            if (status === 'succeed') {
                message = `collection ${collection_name} 更新成功, 当前记录数为 ${rst.cnt}`
            } else {
                message = `collection ${collection_name} 更新失败: ${err_msg}`
                variant = 'error'
            }
            dispatch({
                type: 'MSG_COLLECTION_IMPORT_PROGRESS',
                value: {
                    msg: {
                        collection_id,
                        status,
                        rst: {
                            ...rst,
                            current: 1,
                            total: 1
                        }
                    }
                }
            })
            enqueueBar({
                message,
                options: {
                    ...options,
                    variant
                }
            })
            break
        case 'COLLECTION_DELETE':
            if (status === 'succeed') {
                message = `collection ${collection_name} 删除成功`
                dispatch({
                    type: `MSG_${type}`,
                    value: response
                })
            } else {
                message = `collection ${collection_name} 删除失败: ${err_msg}`
                variant = 'error'
            }
            enqueueBar({
                message,
                options: {
                    ...options,
                    variant
                }
            })
            break
        default:
            dispatch({
                type: `MSG_${type}`,
                value: response
            })
            break
    }
}
