import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { removeMessage } from 'store/actions'

let displayed = []

const WsConnect = (props) => {
    const dispatch = useDispatch()

    const messager = useSelector((state) => state.messager)
    const { messages } = messager || {}

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const token = localStorage.getItem('token')
    const { connected } = useSelector((state) => state.global)

    const storeDisplayed = (id) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter((key) => id !== key)]
    }

    useEffect(() => {
        messages.forEach((data) => {
            const { key, message, options = {}, dismissed = false } = data || {}
            if (dismissed) {
                closeSnackbar(key)
                return
            }

            if (displayed.includes(key)) return

            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey)
                    }
                },
                onExited: (event, myKey) => {
                    dispatch(removeMessage(myKey))
                    removeDisplayed(myKey)
                }
            })

            storeDisplayed(key)
        })
    }, [messages, closeSnackbar, enqueueSnackbar, dispatch])

    useEffect(() => {
        if (!connected && token) {
            dispatch({
                type: 'CONNECT_READY'
            })
        }
    }, [connected, token, dispatch])

    return <>{props.children}</>
}

WsConnect.propTypes = {
    children: PropTypes.node
}

export default WsConnect
