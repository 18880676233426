import axios from 'axios'
import { LRUCache } from 'lru-cache'
import { apiEntry } from 'store/constant'

const defaultKey = (req) =>
    [req.method, req.url, JSON.stringify(req.params), req.data, req.headers.Authorization, req.headers.ProjectId].join('||')

function createAdapter(adapterConfig) {
    const { max = 100, ttl = 2 * 1000, adapter = axios.defaults.adapter } = adapterConfig
    const cache = new LRUCache({ max, ttl })

    return function (config) {
        // 临时禁用缓存
        if (config.cache === false) {
            return axios.defaults.adapter(config)
        }

        // 临时替换 key 函数
        const key = config.cache?.key || adapterConfig.key || defaultKey
        const cacheKey = key(config)
        const cached = cache.get(cacheKey)
        if (!cached) {
            const promise = adapter(config)
            cache.set(cacheKey, promise)
            promise.catch(() => cache.delete(cacheKey))
            return promise
        }
        return cached
    }
}

const adapter = createAdapter({})

const apiClient = axios.create({
    baseURL: `${apiEntry}`,
    headers: {
        'Content-type': 'application/json'
    },
    adapter
})

apiClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token')

    if (token) {
        config.headers = config.headers || {}
        config.headers.Authorization = `Bearer ${token}`
    }

    return config
})

apiClient.interceptors.response.use(async (response) => {
    const { errno, err, rst } = response.data || {}

    if (rst?.token) {
        localStorage.setItem('token', rst.token)
    }

    if (errno !== 0) {
        switch (errno) {
            // needLoginCode
            // case 1010100:
            // case 1010105:
            // case 1100007:
            case 1010101: // 用户登录会话超时，请重新登录
            case 1010104:
            case 1100002: // 您没有登录，请重新登录后再试
            case 1100003:
                localStorage.removeItem('projectId')
                localStorage.removeItem('token')
                if (!['/', '/login'].includes(location.pathname)) {
                    localStorage.setItem('loginRedirect', location.href)
                }
                // 没有权限会导致数据错误
                // 没有处理可能不能正常跳转
                // 使用 location 强制跳转
                // navigate('/login', { replace: true })
                window.location.assign('/login')
                // throw new Error(err || '登录已过期,请重新登录')
                break
            case 1200002:
                return {}
            default:
                throw new Error(err)
        }
    }

    return response.data.rst
})

function copy(client) {
    const cpoied = client.create()
    client.interceptors.request.handlers.forEach(({ fulfilled, rejected, ...options }) =>
        cpoied.interceptors.request.use(fulfilled, rejected, options)
    )
    client.interceptors.response.handlers.forEach(({ fulfilled, rejected, ...options }) =>
        cpoied.interceptors.response.use(fulfilled, rejected, options)
    )
    return cpoied
}

export default apiClient
export { copy }
