let navigateInst = null

export function navigate(to, options) {
    if (navigateInst) {
        navigateInst(to, options)
    } else {
        location.assign(to)
    }
}

export function setNavigate(value) {
    navigateInst = value
}
