/* eslint no-case-declarations: 0 */

import ReconnectingWebSocket from 'reconnecting-websocket'
import { isFunction } from 'lodash'
import { apiEntry } from 'store/constant'
import { messageResolve } from './common'
import { v1 as uuid } from 'uuid'

const callback_list = {}
const regex = /^http(?=s?:\/\/)/
const wsOptions = {
    minReconnectionDelay: 1000, // 设置第一次连接无延迟，但参数本身不是设计作为此用途，后续版本等待修复
    reconnectionDelayGrowFactor: 2,
    maxRetries: 2 // 设置最大重试次数
}

const wsMiddleware = () => {
    let ws = {}
    const onOpen = (store) => {
        store.dispatch({
            type: 'UPDATE_GLOBAL_STATE',
            value: {
                type: 'connected',
                payload: true
            }
        })
    }
    const onMessage = (store, response) => {
        if (typeof response === 'string') {
            response = JSON.parse(response)
        }
        let action
        if (response.request_id && (action = callback_list[response.request_id])) {
            delete callback_list[response.request_id]
            action.resolve(response.data)
        }
        messageResolve(store, response)
    }
    // const onClose = (store) => {
    //     store.dispatch({
    //         type: 'UPDATE_GLOBAL_STATE',
    //         value: {
    //             type: 'connected',
    //             payload: false
    //         }
    //     })
    // }

    let timer = null

    return (store) => (next) => (action) => {
        switch (action.type) {
            case 'CONNECT_READY':
                const token = localStorage.getItem('token')
                let wsUrl = `${apiEntry}/users/subscribe?token=${token}`
                if (!regex.test(wsUrl)) {
                    wsUrl = `${window.location.origin}${wsUrl}`
                }
                wsUrl = wsUrl.replace(regex, 'ws')

                clearInterval(timer)
                isFunction(ws?.close) && ws.close()

                try {
                    ws = new ReconnectingWebSocket(wsUrl, [], wsOptions)
                } catch (error) {
                    console.info(error)
                }

                const send = (data) => ws.send(JSON.stringify(data))
                const ping = () => send({ type: 'Ping' })

                ws.onopen = () => {
                    console.info('消息已连接')
                    send({ type: 'Listen' })
                    onOpen(store)
                    timer = setInterval(ping, 30000)
                }
                ws.onclose = (event) => {
                    console.info(`消息已断开，错误码：${event.code}`)
                    // onClose(store)
                }
                ws.onmessage = (event) => {
                    onMessage(store, event.data)
                }
                break
            case 'PUSH_MESSAGE':
                const { command, data } = action.value
                const message = {
                    command,
                    data,
                    request_id: uuid()
                }
                if (action.resolve) {
                    callback_list[message.request_id] = action
                }
                ws.send(JSON.stringify(message))
                break
            case 'DIS_CONNECT':
                clearInterval(timer)
                isFunction(ws?.close) && ws.close()
                // onClose(store)
                break
            default:
                next(action)
        }
    }
}
export default wsMiddleware()
