import { connect } from 'api/km/nodeHistory'

// action - task message
export const CONNECT = '@TaskMessage/CONNECT'
export const DISCONNECT = '@TaskMessage/DISCONNECT'

const defaultState = {
    ws: null,
    flowId: null,
    token: null
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case CONNECT: {
            const { flowId, token } = action
            // 用户重新登录
            if (token === state.token) {
                // 同一流程图
                if (flowId === state.flowId) {
                    // 已连接
                    if (state.ws) {
                        return state
                    }
                }
            }
            const ws = connect(flowId, token)
            return { ...state, ws, flowId, token }
        }
        case DISCONNECT: {
            state.ws?.close?.()
            return { ...state, ...defaultState }
        }
        default:
            return state
    }
}
