import { ENQUEUE_MESSAGE, CLOSE_MESSAGE, REMOVE_MESSAGE } from '../actions'

export const initialState = {
    messages: [],
    collectionMsg: {},
    collectionProgress: {},
    collectionDel: ''
}

const messagerReducer = (state = initialState, action) => {
    const { key, message, dismissAll, value } = action || {}
    const { msg } = value || {}
    const { collection_id, rst: { data_id, name } = {} } = msg || {}
    const collectionMsg = state.collectionMsg[collection_id] || []
    const collectionProgress = state.collectionProgress[collection_id] || {}
    switch (action.type) {
        case ENQUEUE_MESSAGE:
            return {
                ...state,
                messages: [...state.messages, { key, ...message }]
            }

        case CLOSE_MESSAGE:
            return {
                ...state,
                messages: state.messages.map((d) => (dismissAll || d.key === key ? { ...d, dismissed: true } : { ...d }))
            }

        case REMOVE_MESSAGE:
            return {
                ...state,
                messages: state.messages.filter((d) => d.key !== key)
            }

        case 'MSG_COLLECTION_COUNT':
            return {
                ...state,
                collectionMsg: {
                    ...state.collectionMsg,
                    [collection_id]: [
                        {
                            messageType: 'RESP',
                            message: msg,
                            createTime: Date.now()
                        }
                    ]
                }
            }

        case 'MSG_COLLECTION_SEARCH':
            return {
                ...state,
                collectionMsg: {
                    ...state.collectionMsg,
                    [collection_id]: [
                        ...collectionMsg,
                        {
                            messageType: 'RESP',
                            message: msg,
                            createTime: Date.now()
                        }
                    ]
                }
            }

        case 'MSG_COLLECTION_INPUT':
            return {
                ...state,
                collectionMsg: {
                    ...state.collectionMsg,
                    [collection_id]: [
                        ...collectionMsg,
                        {
                            messageType: 'USER_INPUT',
                            message: msg.input,
                            createTime: Date.now()
                        }
                    ]
                }
            }
        case 'MSG_COLLECTION_IMPORT_PROGRESS_START':
            return {
                ...state,
                collectionProgress: {
                    ...state.collectionProgress,
                    [collection_id]: {
                        loading: true
                    }
                }
            }
        case 'MSG_COLLECTION_IMPORT_PROGRESS_RESTART':
            return {
                ...state,
                collectionProgress: {}
            }
        case 'MSG_COLLECTION_IMPORT_PROGRESS':
        case 'MSG_COLLECTION_ADD_LIST_PROGRESS':
            return {
                ...state,
                collectionProgress: {
                    ...state.collectionProgress,
                    [collection_id]: {
                        ...collectionProgress,
                        loading: false,
                        [data_id || name]: msg
                    }
                }
            }

        case 'MSG_COLLECTION_DELETE':
            return {
                ...state,
                collectionDel: collection_id
            }
        default:
            return state
    }
}

export default messagerReducer
