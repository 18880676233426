const defaultState = {
    connected: false,
    token: '',
    is_login: false,
    loading: false
}
export default (state = defaultState, action) => {
    const { type, payload } = action?.value || {}
    switch (action.type) {
        case 'UPDATE_GLOBAL_STATE':
            return { ...state, [type]: payload }
        case 'CONNECT_SUCCESS':
            return { ...state, connected: true }
        default:
            return state
    }
}
