import { castArray, filter, find, flatMap, map, mapKeys, mapValues, omit } from 'lodash'
import defaultNodesData from 'nodes'
import { MarkerType } from 'reactflow'
import client from './projectClient'

function denormalizeNode(body, edges = []) {
    const { name, description, ...config } = body.data.inputs
    const node = {
        id: body.id,
        name,
        description,
        type: body.data.type,
        config: { ...config, edges, d: JSON.stringify(body) },
        remark: body.data.remark,
        pos: [parseInt(body.position.x), parseInt(body.position.y)],
        outputs: [
            {
                ports: map(edges, (edge) => ({
                    nodeId: edge.target,
                    portId: edge.targetPortIndex,
                    condition: edge.data.condition
                }))
            }
        ]
    }
    return node
}

async function normalizeNode(taskId, node) {
    const nodeId = node.id

    const { config } = await client.get(`/task/${taskId}/nodes/${nodeId}`)

    // @2024/4/2 externalDataCollection 改为数组
    config.externalDataCollection = filter(castArray(config.externalDataCollection ?? []), 'id')

    // @2024/4/12 name 从 config 移动到 node，原有 node.name 暂未使用，被覆盖
    config.name = config.name || node.name || Date.now().toString(26)
    // @2024/4/16 添加 node.description
    config.description = node.description

    const [x, y] = node.pos || []
    const nodeDefine = find(defaultNodesData, { type: node.type }) || {}
    const { inputs: inputAnchors = [], outputs: outputAnchors = [] } = nodeDefine
    const inputs = {
        ...mapValues(mapKeys(filter(inputAnchors, 'key'), 'key'), () => ''),
        ...omit(config, 'd', 'edges')
    }
    return {
        width: 300,
        height: 283,
        id: nodeId,
        dragging: false,
        type: 'customNode',
        position: { x, y },
        positionAbsolute: { x, y },
        edges: map(config.edges, (e) => ({
            ...e,
            markerStart: undefined,
            markerEnd: { type: MarkerType.Arrow, width: 30, height: 30 }
        })),
        data: {
            ...nodeDefine,
            inputs,
            inputAnchors: inputAnchors.map((n) => ({
                ...n,
                id: `${nodeId}-input-${n.name}-${n.type}`
            })),
            outputs: {},
            outputAnchors: outputAnchors.map((n) => ({
                ...n,
                id: `${nodeId}-output-${n.name}-${n.type}`
            })),
            id: nodeId,
            // 用于交互中取值，并用于新节点判断
            taskId
        }
    }
}

async function getSpecificFlow(id) {
    const task = (await client.get(`/task/${id}`, { cache: false })) || {}
    const nodeRes = await client.get(`/task/${id}/nodes`)
    const { nodes = [] } = nodeRes || {}
    const flowNodes = await Promise.all(nodes.map(async (d) => normalizeNode(id, d)))
    const flowData = {
        nodes: map(flowNodes, (node) => omit(node, 'edges')),
        edges: flatMap(flowNodes, 'edges').filter((d) => d),
        viewport: {
            x: -95.7061666096871,
            y: -422.48833082166243,
            zoom: 0.6858621805241022
        }
    }
    const data = {
        id,
        name: task.name || '',
        status: task.status,
        flowData,
        deployed: false,
        isPublic: false,
        apikeyid: null,
        chatbotConfig: null,
        apiConfig: null,
        analytic: null,
        createdDate: new Date(task.createTime).toISOString(),
        updatedDate: new Date(task.updateTime).toISOString(),
        category: null
    }
    return data
}

const getSpecificFlowFromPublicEndpoint = (id) => client.get(`/public-flow/${id}`)

const createNewFlow = (body) => client.post(`/flow`, body)

async function createNode(taskId, body) {
    const node = denormalizeNode({ ...body, id: undefined })
    const res = await client.post(`/task/${taskId}/nodes`, node)
    const d = normalizeNode(taskId, res)
    return d
}

async function updateNode(taskId, body, edges) {
    const node = denormalizeNode(body, edges)
    return client.put(`/task/${taskId}/nodes/${body.id}`, node)
}

async function updateNodeConfig(taskId, nodeId, conf) {
    const { position } = conf || {}
    const config = {
        pos: position ? [parseInt(position.x), parseInt(position.y)] : undefined
    }
    return client.put(`/task/${taskId}/nodes/${nodeId}`, config)
}

const removeNode = (taskId, nodeIds) => {
    const params = new URLSearchParams({})
    nodeIds.forEach((id) => {
        params.append('nodeIds', id)
    })
    return client.delete(`/task/${taskId}/nodes`, { params })
}

const updateFlow = async (id, data) => {
    await client.put(`/task/${id}`, data)
    return data
}

const saveFlow = async (id, data, edges) => {
    const nodes = data.map((item) => denormalizeNode(item, filter(edges, { source: item.id })))
    return client.post(`/task/${id}/save`, nodes)
}

const deleteFlow = (id) => client.delete(`/flow/${id}`)

const getIsFlowStreaming = (id) => client.get(`/flow-streaming/${id}`)

async function start(id) {
    return client.post(`/task/${id}/start`)
}

async function stop(id) {
    return client.post(`/task/${id}/stop`)
}

async function previewPrompt(id, nodeId) {
    return client.get(`/task/${id}/promptPreview/${nodeId}`)
}

export default {
    getSpecificFlow,
    getSpecificFlowFromPublicEndpoint,
    createNewFlow,
    updateFlow,
    saveFlow,
    deleteFlow,
    getIsFlowStreaming,
    createNode,
    updateNode,
    updateNodeConfig,
    removeNode,
    start,
    stop,
    previewPrompt
}
