import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/loading/Loadable'
import MainLayout from 'layout/MainLayout'

// task routing
const Task = Loadable(lazy(() => import('views/task')))

// ==============================|| TASK ROUTING ||============================== //

const TaskRoutes = {
    path: '/project/:projectId',
    element: <MainLayout />,
    children: [
        {
            path: '/project/:projectId',
            element: <Task />
        },
        {
            path: '/project/:projectId/task',
            element: <Task />
        }
    ]
}

export default TaskRoutes
