import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/loading/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

// flow routing
const Flow = Loadable(lazy(() => import('views/flow')))

// ==============================|| TASK ROUTING ||============================== //

const TaskRoutes = {
    path: '/project/:projectId/task/:taskId',
    element: <MinimalLayout />,
    children: [
        {
            path: '/project/:projectId/task/:taskId',
            element: <Flow />
        }
    ]
}

export default TaskRoutes
