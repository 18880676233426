import agentSvg from 'assets/images/agent.svg'
import promptSvg from 'assets/images/prompt.svg'
import flow from './flow.jpg'
import teacher from './teacher.png'

export default [
    {
        label: 'ReAct Agent for Chat Models',
        name: 'mrklAgentChat',
        version: 1,
        type: 'chat',
        category: 'Agents',
        icon: agentSvg,
        description: 'Agent that uses the ReAct logic to decide what action to take, optimized to be used with Chat Models',
        baseClasses: ['AgentExecutor', 'BaseChain', 'Runnable'],
        filePath: '/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/MRKLAgentChat/MRKLAgentChat.js',
        inputs: [{ types: ['base'], limit: Infinity }],
        outputs: [{ types: ['base'], limit: Infinity }],
        inputParams: [
            // {
            //     // 标识不同输入组件
            //     // 没有设置路径时也作为路径使用
            //     // 不能为空，只能是字符串
            //     key: 'key',
            //     // 当前组件在数据中对应的路径
            //     // 比如: {a: {b: ['c']}} 中 'c' 的路径是 'a.b[0]'
            //     // 也可以使用数组 ['a', 'b', 0]
            //     // 使用空数组表示使用整个数据
            //     path: 'path',
            //     // 交互组件类型
            //     // 见 inputRenderer 目录
            //     type: 'type',
            //     // 交互组件左边显示的文本
            //     label: 'label',
            //     // 描述
            //     description: 'description',
            //     // 默认值
            //     default: 'default',
            //     // 必选项，会在 label 显示一个红色的 *
            //     required: true,
            //     // 额外的选项，在打开的对话框中输入
            //     // @deprecated 使用 additional 类型替代
            //     additionalParams: true
            //     // 其他交互组件的参数
            //     // ...componentProps
            // },
            {
                key: 'className',
                type: 'selectAgent',
                label: 'Agent Type',
                default: 'ChatAgent',
                disableClearable: true,
                required: true
            },
            {
                key: 'llm',
                type: 'llm',
                label: 'LLM',
                default: {
                    model: 'llm_ehco_openai_gpt4o',
                    frequency_penalty: 0,
                    n: 1,
                    presence_penalty: 0,
                    stream: false,
                    temperature: 1,
                    top_n: 1
                },
                control: {},
                required: true
            },
            {
                key: 'additional',
                path: [],
                type: 'additional',
                params: [
                    {
                        key: 'name',
                        type: 'string',
                        label: 'Name'
                    },
                    {
                        key: 'description',
                        type: 'string',
                        label: 'Description',
                        rows: 2
                    },
                    {
                        key: 'isRoot',
                        type: 'boolean',
                        label: 'Is Root'
                    },
                    {
                        key: 'humanConfigType',
                        path: 'humanConfig.type',
                        type: 'options',
                        label: 'Human Config Type',
                        default: 'none',
                        options: [
                            { label: 'none', name: 'none' },
                            { label: 'always', name: 'always' },
                            { label: 'achivedTimes', name: 'achivedTimes' }
                        ]
                    },
                    {
                        key: 'is(humanConfigTimes)',
                        path: [],
                        type: 'condition',
                        condition: ['equal', 'humanConfig.type', 'achivedTimes'],
                        params: [
                            {
                                key: 'times',
                                path: 'humanConfig.times',
                                type: 'number',
                                label: 'Times',
                                default: 3
                            }
                        ]
                    },
                    {
                        key: 'response',
                        type: 'options',
                        label: 'Response',
                        options: [
                            { label: 'text', name: 'text' },
                            { label: 'json', name: 'json' }
                        ],
                        default: 'text'
                    },
                    {
                        key: 'externalDataCollection',
                        type: 'collections',
                        label: 'External Data Collection',
                        control: {},
                        multiple: true
                    },
                    {
                        key: 'tools',
                        type: 'tools',
                        label: 'Tools',
                        default: []
                    },
                    {
                        key: 'prompt',
                        path: [],
                        type: 'prompt',
                        default: {}
                    }
                ]
            }
        ]
    },
    {
        label: 'Executor',
        name: 'executor',
        version: 1,
        type: 'executor',
        category: 'Agents',
        icon: promptSvg,
        description: 'executor',
        baseClasses: [],
        filePath: '/usr/src/packages/node_modules/flowise-components/dist/nodes/agents/MRKLAgentChat/MRKLAgentChat.js',
        inputs: [{ types: ['base'], limit: Infinity }],
        outputs: [{ types: ['base'], limit: Infinity }],
        inputParams: [
            {
                key: 'executor',
                path: [],
                type: 'selectExecutor',
                label: 'Agent Type',
                control: {},
                required: true
            },
            {
                key: 'additional',
                path: [],
                type: 'additional',
                params: [
                    {
                        key: 'name',
                        type: 'string',
                        label: 'Name'
                    },
                    {
                        key: 'description',
                        type: 'string',
                        label: 'Description',
                        rows: 2
                    },
                    {
                        key: 'isRoot',
                        type: 'boolean',
                        label: 'Is Root'
                    }
                ]
            }
        ]
    },
    {
        label: 'RagQAAgent',
        name: 'mrklAgentChat',
        version: 1,
        type: 'QA',
        category: 'Agents',
        icon: agentSvg,
        description: 'Agent that uses the ReAct logic to decide what action to take, optimized to be used with Chat Models',
        baseClasses: ['AgentExecutor', 'BaseChain', 'Runnable'],
        inputs: [{ types: ['base'], limit: Infinity }],
        outputs: [{ types: ['base'], limit: Infinity }],
        inputParams: [
            {
                key: 'className',
                type: 'select',
                label: 'Agent Type',
                default: 'RagQAAgent',
                options: [{ label: 'RagQAAgent', value: 'RagQAAgent' }],
                required: true,
                control: {}
            },
            {
                key: 'llm',
                type: 'llm',
                label: 'LLM',
                default: {
                    model: 'llm_ehco_openai_gpt4o',
                    frequency_penalty: 0,
                    n: 1,
                    presence_penalty: 0,
                    stream: false,
                    temperature: 1,
                    top_n: 1
                },
                control: {},
                required: true
            },
            {
                key: 'additional',
                path: [],
                type: 'additional',
                params: [
                    {
                        key: 'name',
                        type: 'string',
                        label: 'Name'
                    },
                    {
                        key: 'description',
                        type: 'string',
                        label: 'Description',
                        rows: 2
                    },
                    {
                        key: 'isRoot',
                        type: 'boolean',
                        label: 'Is Root'
                    },
                    {
                        key: 'response',
                        type: 'select',
                        label: 'Response',
                        options: [
                            { label: 'text', value: 'text' },
                            { label: 'json', value: 'json' }
                        ],
                        default: 'text',
                        control: {}
                    },
                    {
                        key: 'externalDataCollection',
                        type: 'collections',
                        label: 'External Data Collection',
                        required: true,
                        control: {}
                    },
                    {
                        key: 'prompt',
                        path: [],
                        type: 'prompt',
                        default: {}
                    },
                    {
                        key: 'additional',
                        path: [],
                        type: 'additional',
                        buttonText: '流程图',
                        params: [
                            {
                                key: 'flow',
                                path: [],
                                type: 'image',
                                src: flow
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        label: 'TeacherAgent',
        name: 'TeacherAgent',
        version: 1,
        type: 'teacher',
        category: 'Agents',
        icon: agentSvg,
        description: 'Agent that uses the ReAct logic to decide what action to take, optimized to be used with Chat Models',
        baseClasses: ['AgentExecutor', 'BaseChain', 'Runnable'],
        inputs: [{ types: ['base'], limit: Infinity }],
        outputs: [{ types: ['base'], limit: Infinity }],
        inputParams: [
            {
                key: 'className',
                type: 'select',
                label: 'Agent Type',
                default: 'TeacherAgent',
                options: [{ label: 'TeacherAgent', value: 'TeacherAgent' }],
                required: true,
                control: {}
            },
            {
                key: 'llm',
                type: 'llm',
                label: 'LLM',
                default: {
                    model: 'llm_ehco_openai_gpt4o',
                    frequency_penalty: 0,
                    n: 1,
                    presence_penalty: 0,
                    stream: false,
                    temperature: 1,
                    top_n: 1
                },
                control: {},
                required: true
            },
            {
                key: 'additional',
                path: [],
                type: 'additional',
                params: [
                    {
                        key: 'name',
                        type: 'string',
                        label: 'Name'
                    },
                    {
                        key: 'description',
                        type: 'string',
                        label: 'Description',
                        rows: 2
                    },
                    {
                        key: 'prompt',
                        path: [],
                        type: 'prompt',
                        default: {}
                    },
                    {
                        key: 'additional',
                        path: [],
                        type: 'additional',
                        buttonText: '流程图',
                        params: [
                            {
                                key: 'teacher',
                                path: [],
                                type: 'image',
                                src: teacher
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
