import { createContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { cloneDeep, filter, find, reject } from 'lodash'
import { SET_DIRTY } from 'store/actions'
import flowApi from 'api/km/flow'

const initialValue = {
    reactFlowInstance: null,
    setReactFlowInstance: () => {},
    duplicateNode: () => {},
    deleteNode: () => {},
    deleteEdge: () => {}
}

export const flowContext = createContext(initialValue)

export const ReactFlowContext = ({ children }) => {
    const dispatch = useDispatch()
    const [reactFlowInstance, setReactFlowInstance] = useState(null)

    const deleteNode = (data) => {
        const { id: nodeId } = data
        deleteConnectedInput(nodeId, 'node')
        reactFlowInstance.setNodes(reactFlowInstance.getNodes().filter((n) => n.id !== nodeId))
        reactFlowInstance.setEdges(reactFlowInstance.getEdges().filter((ns) => ns.source !== nodeId && ns.target !== nodeId))
        dispatch({ type: SET_DIRTY })
    }

    const deleteEdge = (edgeid) => {
        deleteConnectedInput(edgeid, 'edge')
        reactFlowInstance.setEdges(reactFlowInstance.getEdges().filter((edge) => edge.id !== edgeid))
        dispatch({ type: SET_DIRTY })
    }

    const [edgeConfigDialogData, setEdgeConfigDialogData] = useState(null)

    const showEdgeConfigDialog = (val) => {
        setEdgeConfigDialogData(val)
    }

    const updateEdgeConfig = (val) => {
        const edges = reactFlowInstance.getEdges()
        const newEdges = edges.map((e) => (e.id === edgeConfigDialogData.id ? { ...e, data: val } : e))
        reactFlowInstance.setEdges(newEdges)
        setEdgeConfigDialogData(null)
    }

    const deleteConnectedInput = (id, type) => {
        const connectedEdges =
            type === 'node'
                ? reactFlowInstance.getEdges().filter((edge) => edge.source === id)
                : reactFlowInstance.getEdges().filter((edge) => edge.id === id)

        for (const edge of connectedEdges) {
            const targetNodeId = edge.target
            const sourceNodeId = edge.source
            const targetInput = edge.targetHandle.split('-')[2]

            reactFlowInstance.setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === targetNodeId) {
                        let value
                        const inputAnchor = node.data.inputAnchors.find((ancr) => ancr.name === targetInput)
                        const inputParam = node.data.inputParams.find((param) => param.name === targetInput)

                        if (inputAnchor && inputAnchor.list) {
                            const values = node.data.inputs[targetInput] || []
                            value = values.filter((item) => !item.includes(sourceNodeId))
                        } else if (inputParam && inputParam.acceptVariable) {
                            value = node.data.inputs[targetInput].replace(`{{${sourceNodeId}.data.instance}}`, '') || ''
                        } else {
                            value = ''
                        }
                        node.data = {
                            ...node.data,
                            inputs: {
                                ...node.data.inputs,
                                [targetInput]: value
                            }
                        }
                    }
                    return node
                })
            )
        }
    }

    const duplicateNode = async (id) => {
        const nodes = reactFlowInstance.getNodes()
        const originalNode = nodes.find((n) => n.id === id)
        if (originalNode) {
            const clonedNode = cloneDeep(originalNode)
            clonedNode.data.inputs.name = Date.now().toString(26)
            clonedNode.position.x += 400

            const newNode = await flowApi.createNode(clonedNode.data.taskId, clonedNode, [])
            reactFlowInstance.setNodes([...nodes, newNode])
        }
    }

    const updateNode = async (data) => {
        const { taskId } = data
        const nodes = reactFlowInstance.getNodes()
        const node = find(nodes, { id: data.id })
        const edges = reactFlowInstance.getEdges()
        if (/\s+/.test(data.inputs.name) || reject(nodes, { id: data.id })[0]?.data?.inputs?.name === data.inputs.name) {
            throw new Error('The name cannot contain spaces or duplicates.')
        }
        await flowApi.updateNode(taskId, { ...node, data }, filter(edges, { source: data.id }))
    }

    return (
        <flowContext.Provider
            value={{
                reactFlowInstance,
                setReactFlowInstance,
                updateNode,
                deleteNode,
                deleteEdge,
                duplicateNode,
                edgeConfigDialogData,
                setEdgeConfigDialogData,
                showEdgeConfigDialog,
                updateEdgeConfig
            }}
        >
            {children}
        </flowContext.Provider>
    )
}

ReactFlowContext.propTypes = {
    children: PropTypes.any
}
