import { useRoutes } from 'react-router-dom'

// routes
import AuthRoutes from './AuthRoutes'
import MainRoutes from './MainRoutes'
import TaskRoutes from './TaskRoutes'
import FlowRoutes from './FlowRoutes'
import CanvasRoutes from './CanvasRoutes'
import ChatbotRoutes from './ChatbotRoutes'
import config from 'config'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, AuthRoutes, TaskRoutes, FlowRoutes, CanvasRoutes, ChatbotRoutes], config.basename)
}
