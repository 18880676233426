import { map } from 'lodash'
import { apiEntry } from 'store/constant'
import { createWS } from '../../utils/createWS'
import client from './projectClient'
import { getUserCache } from './user'

function normalizeMessage(data, userNickMap, nodeId) {
    const { _id: id, userId, messageType, ...rest } = data
    const user = getUserCache(userId, userNickMap)
    const role = {
        USER_INPUT: 'user',
        RESP: 'ai',
        MONITOR: 'monitor'
    }[messageType]
    return { nodeId, id, user, role, ...rest }
}

export function connect(taskId, token) {
    let url = `${apiEntry}/task/${taskId}/subscribe?token=${token}`
    return createWS(url)
}

export async function query(taskId, nodeId, options) {
    const { lastTime, ...rest } = options || {}
    const nodePath = nodeId ? `/nodes/${nodeId}` : ''
    const query = lastTime ? `?lastTime=${lastTime}` : ''
    const url = `/task/${taskId}${nodePath}/messages${query}`
    const res = await client.get(url, rest)
    const seq = map(res.pageShow.seq, (data) => normalizeMessage(data, res.userNickMap, nodeId))
    return seq.reverse()
}

export async function remove(taskId, nodeId, retainedMsgIds) {
    const url = `/task/${taskId}/nodes/${nodeId}/messages`
    const res = await client.put(url, { retainedMsgIds })
    return res
}

export async function clean(taskId, nodeId) {
    const url = `/task/${taskId}/nodes/${nodeId}/messages`
    const res = await client.delete(url)
    return res
}
