import apiClient, { copy } from './client'

const client = copy(apiClient)

client.interceptors.request.use(function (config) {
    const URLpath = document.location.pathname.toString().split('/')
    const projectId = URLpath[1] === 'project' ? URLpath[2] : ''
    if (projectId) {
        config.headers = config.headers || {}
        config.headers.ProjectId = projectId
    }

    return config
})

export default client
