import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducer'
import wsMiddleware from './middleware/websocket'

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // 关闭不可变性检测中间件
            immutableCheck: false,
            // 关闭序列化状态检测中间件
            serializableCheck: false
        }).concat(wsMiddleware)
})

export default store
