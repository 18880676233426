import ReconnectingWebSocket from 'reconnecting-websocket'

const regex = /^(http|ws)(?=s?:\/\/)/

export function createWS(url) {
    const { origin } = window.location
    const wsUrl = (regex.test(url) ? url : `${origin}${url}`).replace(regex, 'ws')

    const options = {
        minReconnectionDelay: 1000, // 设置第一次连接无延迟，但参数本身不是设计作为此用途，后续版本等待修复
        reconnectionDelayGrowFactor: 2,
        maxRetries: 10 // 设置最大重试次数
    }

    const ws = new ReconnectingWebSocket(wsUrl, [], options)
    ws.sendJson = (data) => ws.send(JSON.stringify(data))
    const ping = () => ws.sendJson({ type: 'Ping' })
    let timer
    ws.addEventListener('open', () => {
        timer = setInterval(ping, 30000)
    })
    // ws.addEventListener('message', ({ data }) => {
    //     const json = JSON.parse(data)
    //     if (json.type !== 'Pong') {
    //         // ws.dispatchEvent()
    //     }
    // })
    ws.addEventListener('close', () => {
        clearInterval(timer)
    })

    return ws
}
