import { combineReducers } from 'redux'

// reducer import
import globalReducer from './reducers/globalReducer'
import customizationReducer from './reducers/customizationReducer'
import canvasReducer from './reducers/canvasReducer'
import notifierReducer from './reducers/notifierReducer'
import messagerReducer from './reducers/messagerReducer'
import dialogReducer from './reducers/dialogReducer'
import taskMessageReducer from './reducers/taskMessageReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    global: globalReducer,
    customization: customizationReducer,
    canvas: canvasReducer,
    notifier: notifierReducer,
    messager: messagerReducer,
    dialog: dialogReducer,
    taskMessage: taskMessageReducer
})

export default reducer
