import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// material-ui
import { ButtonBase } from '@mui/material'
import { MENU_OPEN } from 'store/actions'
// project imports
import config from 'config'
import Logo from 'ui-component/extended/Logo'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const dispatch = useDispatch()
    const handleClick = () => {
        dispatch({ type: MENU_OPEN, id: 'project' })
    }
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath} onClick={handleClick}>
            <Logo />
        </ButtonBase>
    )
}

export default LogoSection
