const Logo = () => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: 150,
                height: 50,
                color: '#8b4fff',
                fontSize: '30px',
                letterSpacing: '8px',
                fontWeight: 500
            }}
        >
            CoHAT
        </div>
    )
}

export default Logo
