import bcryptjs from 'bcryptjs'
import md5 from 'md5'
import client from './client'

const login = async (params) => {
    const salt = bcryptjs.genSaltSync(10)
    const password = bcryptjs.hashSync(md5(params.password), salt)
    const payload = { ...params, password }
    const data = await client.post('/login', payload)
    return data
}

const logout = () => client.post('/logout')

const userCache = {}
export function getUserCache(id, map) {
    const user = { id, name: map[id] }

    if (userCache[id]) {
        return Object.assign(userCache[id], user)
    }
    return (userCache[id] = user)
}

export default {
    login,
    logout
}
